.swiper-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 10px;
  background-color: #808080;
  opacity: 0.5;
  cursor: pointer;
}

.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}
