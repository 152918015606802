.bjHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0 -20px 10px 20px #000000;
  z-index: 1000; /* A higher value could conflict with dimmers and modals. */

  font-family: 'Roboto', 'Arial', sans-serif !important;
}

.bjHeader .container {
  background-color: transparent;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .bjHeader .container {
    margin: 0 !important;
  }
}

@media (min-width: 768px) {
  .bjHeader .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .bjHeader .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .bjHeader .container {
    width: 1170px;
  }
}


/* Sidebar styles */

.bjHeader .sidebarMenuButton {
  line-height: 0;
  text-decoration: none !important;
  margin: 0 0 0 15px;
}

.bjHeader .sidebarMenuButton i {
  font-size: 30px;
  width: unset;
  height: unset;
  margin: 0;
}


/* Logo styles */

.bjHeader .headerLogo {
  position: relative;
  width: 55px;
  height: 55px;
  margin: 0 15px;
  line-height: 0;
}

@media(min-width: 768px) {
  .bjHeader .headerLogo {
    width: 220px;
  }
}

@media(min-width: 1200px) {
  .bjHeader .headerLogo {
    width: 280px;
    height: 70px;
  }
}

.bjHeader .headerLogo .logo {
  position: absolute;
  line-height: 0;
  opacity: 1;
  transition: opacity 1s;
}

@media(min-width: 768px) {
  .bjHeader .headerLogo .logo {
    width: 100%;
    height: 100%;
  }
  .bjHeader .headerLogo .logo img {
    width: 100%;
    height: 100%;
  }
}


/* User Menu styles */

.headerUserMenu {
  width: 60px;
  position: relative;
  margin: 0 15px;
}

@media(min-width: 768px) {
  .headerUserMenu {
    width: 160px;
  }
}

.headerUserMenu .dropdown {
  width: 100%;
}


/* Login Buttons styles */

.bjHeader .loginButtons {
  display: flex;
  justify-content: flex-end;
  margin: 0 8px;
}

.bjHeader .loginButtons .button {
  height: 44px;
  width: 44px;
  position: relative;
  margin: 0 7px;
}

@media(min-width: 992px) {
  .bjHeader .loginButtons .button {
    width: 70px;
  }
  .bjHeader .loginButtons .loginButton {
    width: 235px;
  }
}

.bjHeader .loginButtons .button .baseButton {
  height: 100%;
  width: 100%;
  color: #366ba1 !important;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-decoration: none !important;
}

.bjHeader .loginButtons .button .baseButton i {
  font-size: 18px;
}

.bjHeader .loginButtons .button .baseButton .buttonText {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 6px;
}

.bjHeader .loginButtons .button .baseButton.companyButton,
.bjHeader .loginButtons .button .baseButton.applicantButton {
  color: #ffffff !important;
  border: none;
}

.bjHeader .loginButtons .button .baseButton.companyButton {
  background-image: linear-gradient(to right, #1a344e, #366ba1);
}

.bjHeader .loginButtons .button .baseButton.companyButton:hover {
  background-image: linear-gradient(to right, #122436, #2b639c);
}

.bjHeader .loginButtons .button .baseButton.applicantButton {
  background-image: linear-gradient(to right, #ff8000, #fdb529);
}

.bjHeader .loginButtons .button .baseButton.applicantButton:hover {
  background-image: linear-gradient(to right, #e57606, #f8ad1c);
}
